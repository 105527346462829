<template>
  <div>
    <div :hidden="hiddenLoader" id="loader" class="text-center">
      <b-icon-circle-fill animation="throb" font-scale="4"></b-icon-circle-fill>
    </div>

    <div>
      <div :hidden="hidden">
        <div class="mb-2 d-flex justify-content-end">
          <b-button
            v-if="$hasFonction(auth, 'Ajouter utilisateurs')"
            align-h="between"
            variant="primary"
            @click="createUser()"
          >
            Ajouter un utilisateur
          </b-button>
        </div>

        <b-card no-body title="" class="mt-1">
          <div class="my-2 mx-2 row">
            <!-- search start -->
            <div class="col-lg-8 mb-2">
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Recherche"
                class="col4 mr-5 pr-5"
                style="width: 40%"
              >
              </b-form-input>
            </div>
            <!-- search end -->
            <!-- bouton export start -->

            <div class="col-lg-4">

              <b-button
              variant="outline-primary"
              size="sm"
              @click="ExportFomatExcel"
              class="ml-2"
              >
              <b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
              Exporter
              </b-button>

              <b-button
              variant="outline-primary"
              size="sm"
              @click="ExportAllToFomatExcel"
              class="ml-2"
              >
              <b-icon-file-earmark-arrow-down-fill></b-icon-file-earmark-arrow-down-fill>
              Tout Exporter
              </b-button>

            </div>

            <!-- bouton export end -->
          </div>

          <!-- table start -->
          <b-table
            v-model="ExcelUtilisateur"
            id="my-table"
            responsive
            :fields="headers"
            :items="utilisateurs"
            :per-page="perPage"
            :current-page="currentPage"
            @filtered="onFiltered"
            :filter="filter"
            show-empty
            class="border-bottom"
          >
            <!-- etat start -->
            <template #cell(etat)="data">
              <b-form-checkbox
                v-if="$hasFonction(auth, 'Activer utilisateurs')"
                switch
                inline
                value="1"
                unchecked-value="0"
                name="check-button"
                v-model="data.item.state"
                @change="dialogstatut(data.item)"
              >
              </b-form-checkbox>
            </template>
            <!-- etat end -->

            <!-- iconAction start -->
            <template #cell(actions)="data">
              <b-button
                v-if="$hasFonction(auth, 'Modifier utilisateurs')"
                v-b-modal.modal-modification-utilisateur
                size="sm"
                variant="flat-primary"
                class="btn-icon"
                @click="editUser(data.item)"
              >
                <feather-icon icon="Edit2Icon" />
              </b-button>

              <b-button
                v-if="$hasFonction(auth, 'Modifier utilisateurs')"
                size="sm"
                variant="flat-danger"
                class="btn-icon"
                @click="dialogDelete(data.item)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </template>
            <!-- iconAction end -->

            <template #empty>
              <div class="text-center">
                <span class="text-nowrap">Aucune donnée existante</span>
              </div>
            </template>
            <template #emptyfiltered>
              <div class="text-center">
                <span class="text-nowrap"
                  >L'élément recherché n'est pas dans la liste</span
                >
              </div>
            </template>
          </b-table>
          <!-- table end -->

          <div class="my-2 mx-2 row">
            <!-- per-page end -->
            <div class="col">
              <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                style="width: 70px"
              ></b-form-select>
              <span class="text-nowrap ml-2" v-if="allRows != 0"> 1 - <span v-if="perPage >= allRows">{{ allRows }}</span> 
                <span v-if="perPage < allRows"> {{perPage}} </span> sur {{ allRows }}</span>
                <span class="text-nowrap ml-2" v-if="allRows == 0"> 0 éléments
              </span>
            </div>
            <!-- per-page end -->

            <!-- pagination start -->
            <b-pagination
              class="pagination"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              aria-controls="my-table"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
            <!-- pagination end -->
          </div>
        </b-card>

        <!-- modal modifer utilisateur -->
        <b-modal
          v-model="modal_modification_utilisateur"
          title="Modifier un utilisateur"
          :ok-disabled="isOperating"
          @hidden="resetModal"
		  hide-footer
          centered
        >
          <b-form ref="editForm" @submit.stop.prevent="updateUser">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
                <label for="name">Nom et prénom(s)</label>
                <b-form-input
                  id="name"
                  placeholder="Nom et Prenom"
                  type="text"
                  v-model="modifiedUser.name"
                  @click="resetInputNameModal"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small class="text-danger">{{
                  errors[0] ? "" : errorsNameModal
                }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="email"
                rules="required"
              >
                <label for="email">Email</label>
                <b-form-input
                  v-model="modifiedUser.email"
                  id="email"
                  placeholder="Email"
                  @click="resetInputEmailModal"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small class="text-danger">{{
                  errors[0] ? "" : errorsEmailModal
                }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              v-if="
                modifiedUserRole.libelle == 'Associe' ||
                modifiedUserRole.libelle == 'Chargeur' ||
                modifiedUserRole.libelle == 'Importateur'
              "
            >
              <validation-provider
                #default="{ errors }"
                name="ressources"
                rules="required"
              >
                <label for="ressources">Ressources autorisées</label>
                <v-select
                  id="ressources"
                  v-model="modifiedUser.resource"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  placeholder="Ressources autorisées"
                  label="name"
                  :options="ressources"
                  @click="resetInputRessourcesModal"
                  :state="errors.length > 0 ? false : null"
                  :closeOnSelect="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small class="text-danger">{{
                  errors[0] ? "" : errorsRessourcesModal
                }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              v-if="
                modifiedUserRole.libelle == 'Associe' ||
                modifiedUserRole.libelle == 'Chargeur' ||
                modifiedUserRole.libelle == 'Importateur'
              "
            >
              <validation-provider
                #default="{ errors }"
                name="comptes"
                rules="required"
              >
                <label for="comptes">Comptes autorisés</label>
                <v-select
                  id="comptes"
                  v-model="modifiedUser.accounts"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  placeholder="Comptes autorisés"
                  label="libelle"
                  :options="accounts"
                  @click="resetInputComptesModal"
                  :state="errors.length > 0 ? false : null"
                  :closeOnSelect="false"
                />
                <small class="text-danger">{{ errors[0] }}</small>
                <small class="text-danger">{{
                  errors[0] ? "" : errorsComptesModal
                }}</small>
              </validation-provider>
            </b-form-group>

			<b-form-group class="mb-0">
				<b-button
					variant="outline-secondary"
					type="reset"
					class="mt-2 mr-1"
					@click="modal_modification_utilisateur=false"
				>
					Annuler
				</b-button>

				<b-button
					v-if="etatButtonEdit"
					variant="primary"
					class="mt-2"
					@click="handleOk"
				>
					Enregistrer
				</b-button>

				<b-button 
					v-else variant="primary"
					class="mt-2"
				>
					<b-icon-arrow-clockwise
						animation="spin"
						font-scale="1"
					></b-icon-arrow-clockwise>
					En cours
				</b-button>
			</b-form-group>
          </b-form>
        </b-modal>
      </div>

      <div :hidden="hiddenEmpty" class="text-center">
        <empty-list></empty-list>
        <b-button variant="primary" size="sm" @click="createUser()">
          <b-icon-plus></b-icon-plus>
          AJOUTER
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import EmptyList from "@core/components/empty-list/EmptyList.vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BIconCircleFill,
  BIconPlus,
  BCard,
  BTable,
  BIconFileEarmarkArrowDownFill,
  BRow,
  BCol,
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BPagination,
  BFormSelect,
  BIconArrowClockwise,
  BFormCheckbox,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { json2excel, excel2json } from "js2excel";
import downloadExcel from "json2excel-js";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";
import VueSweetalert2 from "vue-sweetalert2";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, integer, alpha, alphaDash } from "@validations";

export default {
  components: {
    VueSweetalert2,
    VueGoodTable,
    BCardCode,
    BPagination,
    BFormSelect,
    BFormCheckbox,
    EmptyList,
    BIconPlus,
    BRow,
    BCol,
    BCard,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,
    BCardText,
    BTable,
    BIconCircleFill,
	BIconArrowClockwise,
    BIconFileEarmarkArrowDownFill,
    ValidationProvider,
    ValidationObserver,
    json2excel,
    excel2json,
    downloadExcel,
  },
  data() {
    return {
      hidden: true,
      hiddenEmpty: true,
      hiddenLoader: false,
	  etatButtonEdit: true,
      selected: "Administrateur",
      option: ["Administrateur", "Associe", "Chargeur"],
      perPage: 10,
      currentPage: 1,
      pageOptions: [],
      allRows: 0,
      dir: false,
      ExcelUtilisateur: null,
      headers: [
        {
          key: "name",
          label: "Nom & Prénom(s)",
          sortable: true,
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
        },
        {
          key: "telephone",
          label: "Téléphone",
          sortable: true,
        },
        {
          key: "user_group.libelle",
          label: "Role",
          sortable: true,
        },
        {
          key: "etat",
          label: "Etat",
          sortable: true,
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
      utilisateurs: [],
      filter: "",
      nameState: null,
      modal_modification_utilisateur: false,
      groupesListe: [],
      hasResources: false,
      selectedAccountType: "",
      ressources: [],
      ressourceModel: [],
      accounts: [],
      accountModel: [],
      errorsNameModal: null,
      errorsEmailModal: null,
      errorsRessourcesModal: null,
      errorsComptesModal: null,
      password: "",
      isOperating: false,
      user: {},
      userSet: {},
      modifiedUser: {},
      modifiedUserRole: {},
    };
  },
  computed: {
    ...mapState({
      auth: (state) => state.user.auth,
    }),
    totalRows() {
      this.pageOptions = [10, 25, 50, 100, { value: "10000000000000000000000000", text: "Tout" } ]
      return this.allRows;
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    this.getUsers();
  },
  mounted() {
    this.getGroups();
     this.getComptes()
  },
  methods: {
    showToast(titre, type) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: titre,
          icon: type === "success" ? "CheckSquareIcon" : "AlertCircleIcon",
          variant: type,
        },
      });
    },

    onFiltered(filteredItems) {
        this.allRows =  filteredItems.length 
        this.currentPage = 1
      },

    dialogDelete(deletedItem) {
      this.$bvModal
        .msgBoxConfirm("Voulez vous vraiment supprimer cet utilisateur?", {
          title: "supprimer un utilisateur",
          okVariant: "danger",
          cancelVariant: "outline-secondary",
          okTitle: "Confirmer",
          cancelTitle: "Annuler",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.deleteItem(deletedItem);
          }
        });
    },
    dialogstatut(item) {
      this.userSet = item;
      this.$bvModal
        .msgBoxConfirm(
          "Vous êtes sur le point de changer le statut de l'utiilsateur" +
            item.name +
            ". Voulez-vous continuer ?",
          {
            title: "Changer le statut",
            okVariant: "primary",
            cancelVariant: "outline-secondary",
            okTitle: "Confirmer",
            cancelTitle: "Annuler",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.updateStatut();
          } else {
            if (item.state === 0 || item.state === "0") {
              item.state = 1;
            } else {
              item.state = 0;
            }
            item = {};
          }
        });
    },
    resetModal() {
      this.modifiedUser.name = "";
      this.modifiedUser.email = "";
      this.modifiedUser.resource = "";
      this.modifiedUser.accounts = "";
      this.nameState = null;
      this.resetInputModal();
    },
    resetInputModal() {
      this.resetInputNameModal();
      this.resetInputEmailModal();
      this.resetInputRessourcesModal();
      this.resetInputComptesModal();
    },
    resetInputNameModal() {
      if (this.errorsNameModal) {
        this.errorsNameModal = null;
      }
    },
    resetInputEmailModal() {
      if (this.errorsEmailModal) {
        this.errorsEmailModal = null;
      }
    },
    resetInputRessourcesModal() {
      if (this.errorsRessourcesModal) {
        this.errorsRessourcesModal = null;
      }
    },
    resetInputComptesModal() {
      if (this.errorsComptesModal) {
        this.errorsComptesModal = null;
      }
    },
    getUsers() {
      this.$http.get("/users").then((response) => {
        if (response.status) {
          this.hiddenLoader = true;
        }

        this.utilisateurs = response.data.data.reverse();

        if (this.utilisateurs.length) {
          this.hidden = false;
          this.hiddenEmpty = true;
        } else {
          this.hidden = true;
          this.hiddenEmpty = false;
        }

        this.allRows = this.utilisateurs.length
      });
    },
    createUser() {
      this.$router.push({
        name: "utilisateurs-create",
        params: { id: "123" },
      });
    },
    editUser(item) {
      this.checkSelectedGroup(item.user_group.libelle);
      this.modifiedUser = { ...item, ...{} };
      this.modifiedUserRole = { ...item.user_group, ...{} };
      this.modal_modification_utilisateur = true;
    },
    getGroups() {
      this.$http
        .get("/user-groupes")
        .then((response) => {
          this.groupesListe = response.data.data;
        })
        .then(() => {
          //this.checkSelectedGroup()
        });
    },

    checkSelectedGroup(groupSelected) {
      let data = this.groupesListe.find(
        ({ libelle }) => libelle == groupSelected
      );

      if (data) {
        this.selectedAccountType = data.libelle;

        let table = ["Chargeur", "Associe", "Importateur"];

        if (table.includes(this.selectedAccountType)) {
          this.hasResources = true;
          this.getDatas(this.selectedAccountType);
        } else {
          this.hasResources = false;
        }
      }
    },

    getDatas(role) {
      let axiosValue = "";
      switch (role) {
        case "Associe":
          axiosValue = "associes-acheteurs";
          break;
        case "Chargeur":
          axiosValue = "chargeurs";
          break;
        case "Importateur":
          axiosValue = "importateurs";
          break;

        default:
          break;
      }
      this.ressources = [];
      this.user.resource = [];
      this.ressourceModel = [];
      this.accountModel = [];
      this.user.accounts = [];
      this.$http.get("/" + axiosValue).then((response) => {
        this.ressources = response.data.data;
      });
    },

    getComptes() {
      this.$http.get("/comptes").then((response) => {
        this.accounts = response.data.data;
      });
    },

    generate() {
      let size = 8;
      let characters = "'a-z,A-Z,0-9,#'";
      let charactersArray = characters.split(",");
      let CharacterSet = "";
      let password = "";

      // if (charactersArray.indexOf('a-z') >= 0) {
      CharacterSet +=
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      // }
      // if (charactersArray.indexOf('A-Z') >= 0) {
      //   CharacterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
      // }
      // if (charactersArray.indexOf('0-9') >= 0) {
      //   CharacterSet += '0123456789'
      // }
      // if (charactersArray.indexOf('#') >= 0) {
      //   CharacterSet += '![]{}()%&*$#^<>~@|'
      // }

      for (let i = 0; i < size; i++) {
        password += CharacterSet.charAt(
          Math.floor(Math.random() * CharacterSet.length)
        );
      }
      this.password = password;
    },
    deleteItem(deletedItem) {
      const index = this.utilisateurs.indexOf(deletedItem);
      this.$http
        .delete("/users/" + deletedItem.id, deletedItem)
        .then((result) => {
          this.isOperating = false;
          this.utilisateurs.splice(index, 1);
          this.showToast("Supression effectuée avec succès", "success");
        })
        .catch((err) => {
          this.isOperating = false;
          this.showToast(err.response.data.message, "error");
        });
    },
    async saveUser() {
      this.isOperating = true;
      this.user.password = this.password;
      this.user.password_confirmation = this.password;
      this.user.resource = this.ressourceModel;
      this.user.accounts = this.accountModel;
      this.$http
        .post("/register", this.user)
        .then((result) => {
          this.isOperating = false;
          if (result.data.success == false) {
            let error = result.data.errors;
            error = error[Object.keys(error)[0]][0];
            this.showToast(error, "danger");
          } else {
            this.showToast(
              "Nouvel utilisateur enregistré avec succès",
              "success"
            );
            this.getUsers();
          }
        })
        .catch((err) => {
          this.isOperating = false;
          let error = err.response.data.errors;
          error = error[Object.keys(error)[0]][0];
          this.showToast(error, "danger");
        });
    },

    checkFormValidity() {
      const valid = this.$refs.editForm.checkValidity();
      this.nameState = valid;
      return valid;
    },

    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();

      // Trigger submit handler
      this.updateUser();
    },

    updateUser() {
      
      if (!this.checkFormValidity()) {
        return;
      }
      this.isOperating = true

	  this.etatButtonEdit = false

      this.$http
        .put("/users/" + this.modifiedUser.id, this.modifiedUser)
        .then((result) => {
          this.isOperating = false
          if (result.data.success == false) {
			this.etatButtonEdit = true
            let error = result.data.errors;
            error = error[Object.keys(error)[0]][0];
            this.showToast(error, "danger");
          } else {
			this.etatButtonEdit = true
            this.modal_modification_utilisateur = false;
            this.showToast("Modification effectuée avec succès", "success");
            this.getUsers();
          }
        })
        .catch((e) => {
		  this.etatButtonEdit = true
          this.isOperating = false

          let err = e.response.data.errors;

          if (err.name) {
            this.errorsNameModal = err.name[0];
          }

          if (err.email) {
            this.errorsEmailModal = err.email[0];
          }

          if (err.ressource) {
            this.errorsRessourcesModal = err.ressource[0];
          }

          if (err.Comptes) {
            this.errorsComptesModal = err.Comptes[0];
          }
        });

      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide("modal-prevent-closing");
      });
    },
    updateStatut() {
      // this.userSet.statut = !this.userSet.statut
      // this.isOperating = true
      this.userSet.state = !this.userSet.state;
      this.$http
        .put("/commuter-user/" + this.userSet.id, {})
        .then((result) => {
          this.userSet.state = result.data.data.state;
          // this.isOperating = false
          if (!result.data.success) {
            this.showToast("Mise à jour non effectuée", "danger");
          } else {
            this.getUsers();
            this.showToast("Mise à jour effectuée", "success");
          }
        })
        .catch(() => {
          this.isOperating = false;
          this.showToast("Mise à jour non effectuée", "danger");
        });
    },

    ExportFomatExcel() {
      let { json2excel, excel2json } = require("js2excel");
      // let headers = this.headers
      let data = [];
      let obj = {};

      this.ExcelUtilisateur.forEach((element) => {
        obj = {
          "NOM & PRÉNOM(S)": element.name,
          EMAIL: element.email,
          TÉLÉPHONE: element.telephone,
          ROLE: element.user_group.libelle,
          ETAT: element.state ? "actif" : "inactif",
        };

        data.push(obj);
      });

      if (data) {
        try {
          json2excel({
            // headers,
            data,
            name: "FlashCar - Liste des users",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.log(e);
        }
      }
    },


    ExportAllToFomatExcel() {
      let { json2excel, excel2json } = require("js2excel");
      // let headers = this.headers
      let data = [];
      let obj = {};

      this.utilisateurs.forEach((element) => {
        obj = {
          "NOM & PRÉNOM(S)": element.name,
          EMAIL: element.email,
          TÉLÉPHONE: element.telephone,
          ROLE: element.user_group.libelle,
          ETAT: element.state ? "actif" : "inactif",
        };

        data.push(obj);
      });

      if (data) {
        try {
          json2excel({
            // headers,
            data,
            name: "FlashCar - Liste des users",
            formateDate: "yyyy/mm/dd",
          });
        } catch (e) {
          console.log(e);
        }
      }
    },

  },
};
</script>

<style lang="scss" >
// :components="{Deselect, OpenIndicator}"
// .vs__deselect span{
// 	color:red;
// }

#loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #f8f8f8;
  padding-top: 15%;
  z-index: 1000;
}

.vs__selected .vs__deselect {
  background-color: #ff0000bf;
}

.v-select .vs__dropdown-menu {
  height: 100px;
}

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
